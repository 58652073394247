<template>
   <vSelect :appendToBody="true" :options="options" id='mySelect'
            :style="getAppStyle()+'width: '+width+' !important;  font-size: 9pt; border: 1px solid #ccc !important;'" 
            v-model="value" :multiple="multiple"  @input="$emit('input', value)">  
            
			<template #option="{ id, label }">
      					<span style='font-size: 8pt; height: 14pt;'>{{label}}</span>
    		</template>
    		
    		
    		
    		<template v-if="!multiple"
		      #selected-option-container="{ option }"
		    >
		      <div  :style="getAppStyle()+'color:#000; padding-left: 1pt; padding-right: 2pt; maxWidth: calc(20vH) !important;'" class='selectedOption'>{{ option.label }} </div>
		    </template>
		    
    		<template #no-options="{  }">
             <div :style="'font-size: 8pt; color: #666; position: relative; top: 0; margin:0;'">Sorry, no matching options</div>
             </template> 
            
   </vSelect>


</template>
<script>
import { getAppStyle, getBGStyle3, initAppMode } from '@/AppStyle.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: {
	  multiple: { type: Boolean, default: false },
	  width: { type: String, default: '100%'},
	  value: Object,
	  options: Array
  },
 components : {
	 vSelect
  },
  data () {
    return {
    	getAppStyle
    }
  },
  methods: {
	  getWidth()
	  {
		  alert(document.querySelector('#myselect').clientWidth) 
		  return document.querySelector('#myselect').clientWidth-20;
	  }
  },
  
  created() {
	  initAppMode();
  },
  watch: {
      
  }
 }
</script>
<style lang="scss" scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.iButton {

}
.selectedOption { 
   font-size: 12px; 
   height: 18px !important; 
   //max-height: 36px !important; 
   //width: calc(20vH);
   margin-top: 2pt;
   display: inline-flex;
   margin-right: 2pt;
   white-space: nowrap;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
   //border: 1px solid #ccc;
   background-color: #eef !important;
   color: #000;
}
li {
 background-color: red !important;
}
.vs__selected {
	background-color: transparent !important;
}
</style>